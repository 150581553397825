
import { set } from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CornerRibbon extends Vue {
  @Prop(String) readonly label!: string;
  @Prop(String) readonly color!: string;
  @Prop({ type: String, default: "top-left" }) readonly position!: string;

  @Prop(Boolean) readonly sm!: boolean;
  @Prop(Boolean) readonly base!: boolean;
  @Prop(Boolean) readonly lg!: boolean;
  @Prop(Boolean) readonly xl!: boolean;

  get cssClass(): Record<string, boolean> {
    const obClass = { ribbon: true };
    let sSize = "sm";
    set(obClass, `ribbon__${this.position}`, true);

    if (this.color) {
      set(obClass, this.color, true);
    }

    if (this.lg) {
      sSize = "lg";
    } else if (this.base) {
      sSize = "base";
    } else if (this.xl) {
      sSize = "xl";
    }

    set(obClass, `ribbon__${sSize}`, true);

    return obClass;
  }
}
